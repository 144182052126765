<template>
  <v-row justify="center" class="my-4">
      <div v-for="(product, i) in products" :key="i" class="col-6 col-sm-3 mx-0">
          <!-- <mini-product-card v-if="isMobile" :product_details="product"> </mini-product-card> -->
          <new-product-card :product_details="product"> </new-product-card>
      </div>
  </v-row>
</template>
<script>
import MiniProductCard from "../Product/MiniProductCard";
import NewProductCard from "../Product/NewProductCard";
export default {
  name: "CategoryCard",
  props: ['products'],
  data() {
    return {
        cat_products:[],
    };
  },
    mounted(){
      this.getProducts();
    },
    methods:{
        getProducts(){
            this.$store.dispatch('getProducts').then(res=>{
                this.cat_products = res.data.products;
            });
        },
    },
  components: {
    NewProductCard,
    MiniProductCard
  }
};
</script>

<style scoped lang="scss">

</style>
