<template>
  <v-col cols="12" class="my-4">
    <v-row justify="center">
      <v-parallax
        style="border-radius:5px"
        class="elevation-0 col-12"
        height="320"
        :src="`${imgHost}imgs/category/business_list.png`"
      >
      </v-parallax>
    </v-row>
    <middle-ware class="mt-6" headline="Our Partners"></middle-ware>
    <!--    Company Search bar-->

    <v-toolbar dense flat class="mx-10 px-8 mb-2 mt-4 transparent">
      <v-text-field
        placeholder="Find a listed business ..."
        outlined
        dense
        class="mt-7 ml-8"
        v-model="search"
      ></v-text-field>

      <v-btn outlined rounded class="red darken-1 ml-10 elevation-8" dark
        ><v-icon left>mdi-magnify</v-icon> Search</v-btn
      >
    </v-toolbar>

    <v-layout class="my-4" wrap sm12 md12>
      <v-flex sm6>
        <v-card
          style="border-radius:10px"
          v-for="(item, index) in items"
          :key="index"
          max-width="550"
          class="mx-auto my-4"
        >
          <v-list three-line>
            <v-list-item :key="item.title">
              <v-list-item-avatar>
                <v-img
                  :src="require('../../../assets/imgs/rooster.png')"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{
                  item.title
                }}</v-list-item-title>
                <v-list-item-subtitle
                  class=" subheading grey--text text--darken-1"
                >
                  <v-icon small color="red">mdi-map-marker</v-icon
                  >{{ item.location }}</v-list-item-subtitle
                >
                <v-list-item-subtitle class="caption ml-4">
                  <v-icon small color="primary">mdi-cart</v-icon>
                  {{ item.products }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>
                  <v-btn small rounded dark color="primary"
                    >View More</v-btn
                  ></v-list-item-action-text
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex sm6>
        <v-card
          style="border-radius:10px"
          v-for="(item, index) in items"
          :key="index"
          max-width="550"
          class="mx-auto my-4"
        >
          <v-list three-line>
            <v-list-item :key="item.title">
              <v-list-item-avatar>
                <v-img
                  :src="require('../../../assets/imgs/rooster.png')"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{
                  item.title
                }}</v-list-item-title>
                <v-list-item-subtitle
                  class=" subheading grey--text text--darken-1"
                >
                  <v-icon small color="red">mdi-map-marker</v-icon
                  >{{ item.location }}</v-list-item-subtitle
                >
                <v-list-item-subtitle class="caption ml-4">
                  <v-icon small color="primary">mdi-cart</v-icon>
                  {{ item.products }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>
                  <v-btn rounded small dark color="primary"
                    >View More</v-btn
                  ></v-list-item-action-text
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </v-col>
</template>
<script>
import MiddleWare from "../../../components/Website/MiddleWare";
import CategoryCard from "../../../components/Website/Category/CategoryCard";
import PhoneRing from "../../../components/Website/PhoneRing";
export default {
  name: "BusinessListings",
  data() {
    return {
      search: "",
      title: "Eggs Category",
      items: [
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
        {
          title: "Agroinnova Farms",
          location: "Adabraka, Accra-Ghana",
          products: "Fresh Eggs, Local Poultry, Quail Eggs"
        },
      ]
    };
  },
  mounted() {},
  methods: {},
  components: {
    CategoryCard,
    MiddleWare,
    PhoneRing
  }
};
</script>

<style lang="scss" scoped>
.porto {
  background: rgba(255, 255, 255, 0.6);
}
.dud {
  color: #fff;
  font-size: 90px;
  font-weight: bold;
  line-height: 1em;
  margin: 0;
  text-align: left;
  transform: translateY(-50%);
  width: 100%;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.social-fo li a {
  width: 35px;
  height: 35px;
  display: block;
  background: #3b5998;
  border-radius: 100px;
  color: #fff;
  line-height: 2.5em;
}
.social-fo li a:hover {
  transform: rotate(-360deg);
}
.social-fo li a.twi {
  background: #4099ff;
}
.social-fo li a.pin {
  background: #cd2830;
}
.social-fo li a.dri {
  background: #e35e90;
}
</style>
